@charset "UTF-8";
@import "../lib/cmn_css/base/easings";
@import "../lib/cmn_css/base/colors";
@import "../lib/cmn_css/base/settings";
@import "../lib/cmn_css/base/mixins";
@import "../lib/cmn_css/utils/animations";

#header {
  display: none;
}

#contents {
  background-color: transparent;
}

#mv {
  .mvInr {
    position: relative;
    background-attachment: fixed;
    height: 100vh;
    background-position: center center;
    background-size: cover;
    max-width: 100%;
    width: 100%;
  }

  @include pc {
    .mvInr {
      background-attachment: initial;
    }
  }
}

#news {
  padding-top: 120px;
  .txtBox {
    .head01 {
      float: left;
    }
    a {
      float: right;
      @include Montserrat;
      font-size: 14px;
      position: relative;
      &:hover {
        letter-spacing: 2px;
        text-decoration: none;
      }
      &::after {
        position: absolute;
        left: 0;
        bottom: -5px;
        content: "";
        height: 2px;
        background-color: #000;
        width: 100%;
      }
    }
  }
}

#concept {
  .conceptBox {
    display: table;
    width: 100%;
    .txt {
      display: table-cell;
      table-layout: fixed;
      vertical-align: middle;
      width: 50%;
      text-align: center;
      background-color: $c01;
      color: #fff;
      p {
        font-weight: 500;
        margin-bottom: 30px;
      }
      h2 {
        margin-bottom: 30px;
      }
      h3 {
        margin-bottom: 20px;
        font-size: 32px;
      }
      .bscBtn01 {
        margin-bottom: 0;
        a {
          &:hover {
            background-color: #fff;
            color: $c01;
            &::before {
              border-bottom: 1px solid $c01;
            }
            &::after {
              border-bottom: 1px solid $c01;
            }
          }
        }
      }
    }
    .img {
      display: table-cell;
      table-layout: fixed;
      vertical-align: middle;
      width: 50%;
      height: 450px;
      background-image: url(../images/concept.jpg);
      background-size: cover;
      background-position: center center;
    }
  }
}

#plant {
  padding: 0;
  .title {
    position: relative;
    span {
      @include posCenterX;
      bottom: 0;
      left: 65%;
      font-size: 12px;
      display: inline-block;
      @media (max-width: 1700px) {
        left: 70%;
      }
      @media (max-width: 1250px) {
        left: 78%;
        width: 35%;
      }
      @include pc {
        @include posCenter;
        top: auto;
        bottom: -20px;
        width: 100%;
        text-align: center;
      }
      @include tab {
        bottom: -35px;
      }
    }
  }
  li {
    float: left;
    width: calc(100% / 3);
    .imgInr {
      overflow: hidden;
    }
    .img {
      background-size: cover;
      background-position: center center;
      height: 300px;
      overflow: hidden;
      z-index: 1;
      position: relative;
      @include transition;

      &:after {
        content: "";
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include transition;
      }
    }
    .txt {
      text-align: center;
      padding: 20px 0;
      font-weight: 600;
      border-right: $c02 solid 1px;
    }
    &:hover {
      .img {
        transform: scale(1.1);
        &:after {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
      a {
        text-decoration: none;
      }
      @include tab {
        .img {
          transform: scale(1);
          &:after {
            display: none;
          }
        }
        a {
          text-decoration: none;
        }
      }
    }
    &:nth-child(1) {
      .img {
        background-image: url(/lib/cmn_img/plant01.jpg);
      }
    }
    &:nth-child(2) {
      .img {
        background-image: url(/lib/cmn_img/plant02.jpg);
      }
    }
    &:nth-child(3) {
      border-right: none;
      .img {
        background-image: url(/lib/cmn_img/plant03.jpg);
      }
    }
    &:nth-child(4) {
      .img {
        background-image: url(/lib/cmn_img/plant04.jpg);
      }
    }
    &:nth-child(5) {
      .img {
        background-image: url(/lib/cmn_img/plant05.jpg);
      }
    }
    &:nth-child(6) {
      border-right: none;
      .img {
        background-image: url(/lib/cmn_img/plant06.jpg);
      }
    }
  }
}

#support {
  background-color: $c02;
  .supportBox {
    ul {
      li {
        text-align: center;

        .supportNum {
          @include Montserrat;
          color: $c01;
          font-weight: 400;
          padding-bottom: 15px;
          letter-spacing: 4px;
        }

        .img {
          max-width: 100%;

          img {
            width: 100%;
          }
        }

        .title {
          background-color: $c01;
          color: #fff;
          font-weight: 500;
          font-size: 24px;
          letter-spacing: 3px;
          padding: 6px 0;
        }

        .txt {
          text-align: left;
          line-height: 2.2 !important;
          padding-top: 15px;
        }
      }
    }
  }
}

#recruit {
  .txtBox {
    margin-bottom: 20px;
  }
  .recruitBox {
    border-bottom: $c02 1px solid;
    padding-bottom: 60px;
    margin-bottom: 0px;
    display: table;
    width: 100%;
    .txt {
      display: table-cell;
      table-layout: fixed;
      vertical-align: middle;
      width: 50%;
      text-align: center;
      background-color: $c01;
      color: #fff;
      p {
        font-size: 16px;
        margin-bottom: 30px;
      }
      .bscBtn01 {
        a {
          &::before,
          &::after {
            display: none;
          }
          i {
            font-size: 13px;
            right: 12%;
            top: 38%;
            &::before {
              color: #fff;
            }
          }
          &:hover {
            background-color: #fff;
            color: $c01;
            i {
              &::before {
                color: $c01;
              }
            }
          }
        }
      }
    }
    .img {
      display: table-cell;
      table-layout: fixed;
      vertical-align: middle;
      width: 50%;
      height: 320px;
      background-image: url(../images/recruit.jpg);
      background-size: cover;
      background-position: center center;
    }
  }
}

#blog {
  padding-top: 0;
  .txtBox {
    margin-bottom: 25px;
    .head01 {
      float: left;
    }
    a {
      float: right;
      @include Montserrat;
      font-size: 14px;
      position: relative;
      &:hover {
        letter-spacing: 2px;
        text-decoration: none;
      }
      &::after {
        position: absolute;
        left: 0;
        bottom: -5px;
        content: "";
        height: 2px;
        background-color: #000;
        width: 100%;
      }
    }
  }
  .blogBox {
    li {
      float: left;
      width: calc(96% / 3);
      margin-right: 2%;
      &:last-child {
        margin-right: 0;
      }
      a {
        &:hover {
          text-decoration: none;
          .img {
            &:after {
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
      time {
        font-size: 14px;
        @include Montserrat;
      }
      .txt {
        font-weight: 500;
        line-height: 1.5;
      }
      .img {
        background-image: url(../images/blog.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        z-index: 1;
        position: relative;
        @include transition;
        &:after {
          content: "";
          height: 100%;
          width: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          @include transition;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

/*-----------------------------------------pc 1024px------------------------------------------*/

@include pc {
  #plant {
    padding: 0;
    li {
      width: calc(100% / 2);
    }
  }

  #recruit {
    .recruitBox {
      .txt {
        width: 40%;
        p {
          font-size: 13px;
        }
      }
      .img {
        width: 60%;
        height: 320px;
      }
    }
  }
}

/*--------------------tab 768px---------------------*/

@include tab {
  #concept {
    .conceptBox {
      display: block;
      width: 100%;
      .txt {
        display: block;
        width: 100%;
        padding: 40px 5px;
      }
      .img {
        display: block;
        width: 100%;
        height: 300px;
      }
    }
  }

  #plant {
    padding: 0;
    .scroll {
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      &::after {
        content: "※フリックで横スクロールができます。";
        display: block;
        font-size: 1em;
        margin: 1em;
        margin-bottom: 2em;
      }
    }
    .scrollInr {
      display: inline-block;
      white-space: nowrap;
    }
    li {
      float: none;
      display: inline-block;
      width: 35%;
    }
  }
  #recruit {
    .recruitBox {
      display: block;
      width: 100%;
      .txt {
        display: block;
        width: 100%;
        padding: 30px;

        p {
          font-size: 16px;
          margin-bottom: 30px;
        }
        .bscBtn01 {
          a {
            i {
              font-size: 13px;
              right: 12%;
              top: 38%;
            }
          }
        }
      }
      .img {
        display: block;
        width: 100%;
        height: 320px;
      }
    }
  }

  #blog {
    .blogBox {
      li {
        float: left;
        width: calc(96% / 2);
        margin-right: 2%;
        margin-bottom: 3%;
        &:nth-child(2) {
          margin-right: 0;
        }
        &:last-child {
          float: none;
          margin: 0 auto;
        }
      }
    }
  }
}

/*--------------------sp 480px---------------------*/

@include sp {
  #concept {
    .conceptBox {
      .txt {
        h3 {
          font-size: 24px;
        }
      }
      .img {
        display: block;
        width: 100%;
        height: 200px;
      }
    }
  }

  #support {
    .supportBox {
      ul {
        li {
          text-align: center;
          .title {
            font-size: 18px;
          }
        }
      }
    }
  }
  #recruit {
    .recruitBox {
      .txt {
        padding: 30px 0;

        p {
          font-size: 12px;
        }
      }
      .img {
        height: 200px;
      }
    }
  }

  #blog {
    padding-top: 0;
    .txtBox {
      margin-bottom: 25px;
      .head01 {
        float: left;
      }
      a {
        float: right;
        @include Montserrat;
        font-size: 14px;
        position: relative;
        &:hover {
          letter-spacing: 2px;
          text-decoration: none;
        }
        &::after {
          position: absolute;
          left: 0;
          bottom: -5px;
          content: "";
          height: 2px;
          background-color: #000;
          width: 100%;
        }
      }
    }
    .blogBox {
      li {
        float: none;
        width: 100%;
        margin: 0;
        margin-bottom: 40px;
      }
    }
  }
}
